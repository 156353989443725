import { useModal } from 'vue-final-modal'
import type { LocationQuery } from 'vue-router'

import Auth from '~/components/modal-windows/Auth.vue'
import ViewRecommendationModalWindow from '~/components/modal-windows/ViewRecommendation.vue'
import { useProduct } from '~/composables/product'
import Action from '~/enums/action'
import AuthAction from '~/enums/auth-action'
import Page from '~/enums/page'
import { useAuthStore } from '~/store/auth'

const {
  AddProductsToCart,
  AddProductsToCart2,
  ConfirmEmail,
  ResetPassword,
  ViewRecommendation,
  ViewRecommendation2
} = Action
const { ChangePassword, VerifyEmail } = AuthAction
const messages = {
  failedToAddProductsToCart: 'Не удалось добавить товары в корзину.',
  productsAreAddedToCart: 'Товары добавлены в корзину.'
}

export function useAction () {
  const { $apiHelper, $toast } = useNuxtApp()
  const route = useRoute()
  const router = useRouter()
  const authStore = useAuthStore()
  const { createGuestIfNeeded } = useProduct()

  async function deleteParamsAndNavigateToNewUrl (paramsToDelete: string[]): Promise<void> {
    const query = { ...route.query }

    for (const x of paramsToDelete) {
      delete query[x]
    }

    await router.replace({ query })
  }

  async function goToMainPageIfNeeded (): Promise<void> {
    if (route.path !== Page.Main) {
      await router.push(Page.Main)
    }
  }

  async function performActionIfNeeded (action: Action, query: LocationQuery): Promise<void> {
    switch (action) {
      case AddProductsToCart:
      case AddProductsToCart2:
        await performAddProductsToCartAction(query)
        break
      case ConfirmEmail:
        await performConfirmEmailAction(query)
        break
      case ResetPassword:
        await performResetPasswordAction(query)
        break
      case ViewRecommendation:
      case ViewRecommendation2:
        await performViewRecommendationAction(query)
        break
      default:
        await goToMainPageIfNeeded()
        break
    }
  }

  async function performConfirmEmailAction (query: LocationQuery): Promise<void> {
    const { token } = query

    if (token) {
      await deleteParamsAndNavigateToNewUrl(['action', 'token'])

      await useModal({ component: Auth, attrs: { action: VerifyEmail, token: token as string } }).open()
    }

    await goToMainPageIfNeeded()
  }

  async function performAddProductsToCartAction (query: LocationQuery): Promise<void> {
    const { productIds } = query

    if (productIds) {
      await deleteParamsAndNavigateToNewUrl(['action', 'productIds'])

      const productIdsAsArray = [...new Set((productIds as string).split(','))]
      const { failedToAddProductsToCart, productsAreAddedToCart } = messages

      try {
        if (!authStore.isUser) {
          await createGuestIfNeeded()
        }

        await $apiHelper.cartProducts.createCartProductsByProductIds(productIdsAsArray)

        $toast.success(productsAreAddedToCart)

        await router.push(Page.Cart)
      } catch (error) {
        console.error(error)

        $toast.error(failedToAddProductsToCart)

        await goToMainPageIfNeeded()
      }
    } else {
      await goToMainPageIfNeeded()
    }
  }

  async function performResetPasswordAction (query: LocationQuery): Promise<void> {
    const { token } = query

    if (token) {
      await deleteParamsAndNavigateToNewUrl(['action', 'token'])

      await useModal({ component: Auth, attrs: { action: ChangePassword, token: token as string } }).open()
    }

    await goToMainPageIfNeeded()
  }

  async function performViewRecommendationAction (query: LocationQuery): Promise<void> {
    const { recommendationId } = query

    if (recommendationId) {
      await deleteParamsAndNavigateToNewUrl(['action', 'recommendationId'])

      await useModal({
        component: ViewRecommendationModalWindow,
        attrs: { recommendationId: recommendationId as string }
      }).open()
    }

    await goToMainPageIfNeeded()
  }

  return { performActionIfNeeded }
}
